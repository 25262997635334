<template>
  <v-carousel
    continuous
    cycle
    hide-delimiters
    :height="$store.state.mediaHeightText"
    :show-arrows="items.length > 1"
    v-if="items.length > 0"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i + item.Header"
      :src="
        $config.managerMedia +
        item.Image +
        `?resize&width=${$store.state.mediaWidth}`
      "
      :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
      gradient="to top, rgba(0,0,0,0.9), rgba(0,0,0,0.05)"
    >
      <v-card
        flat
        :height="$store.state.mediaHeightText"
        style="background-color: transparent"
      >
        <v-card-text style="position: absolute; bottom: 0">
          <v-card-title class="white--text pb-0 pl-1 title">
            {{ item.Header }}
          </v-card-title>
          <v-card-actions class="pl-1">
            <v-btn
              outlined
              text
              v-if="item.Video"
              color="primary"
              @click="onClickVideo(item)"
            >
              <v-icon class="pr-2">mdi-play</v-icon>
              {{ $t("app.play") }}
            </v-btn>
            <v-btn
              v-if="item.Page || item.OnClick"
              color="primary"
              :to="pageOrOnclickItem(item)"
            >
              {{ $t("app.info") }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { getRoute, playVideo } from "@/util/contentpage";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onClickVideo(item) {
      playVideo(item.Video);
    },
    pageOrOnclickItem(item) {
      return getRoute(item);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-contentpage-card',{attrs:{"item":{
      Header: _vm.Header,
      Image: _vm.Image,
      Description: _vm.Description,
      Page: _vm.Page,
      OnClick: _vm.OnClick,
      Video: _vm.Video,
      Action1Title: _vm.Action1Title,
      Action1OnClick: _vm.Action1OnClick,
      Action1Icon: _vm.Action1Icon,
      Action2Title: _vm.Action2Title,
      Action2OnClick: _vm.Action2OnClick,
      Action2Icon: _vm.Action2Icon,
      Action3Title: _vm.Action3Title,
      Action3OnClick: _vm.Action3OnClick,
      Action3Icon: _vm.Action3Icon,
      Action4Title: _vm.Action4Title,
      Action4OnClick: _vm.Action4OnClick,
      Action4Icon: _vm.Action4Icon,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',_vm._b({staticClass:"pa-0"},'v-container',( _obj = {}, _obj[("grid-list-" + (_vm.$vuetify.breakpoint.name))] = true, _obj ),false),[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.list),function(item,index){return (
        (item.Header && item.Header.length > 0) ||
        (item.Image && item.Image.length > 0)
      )?_c('v-flex',{key:index,attrs:{"xs6":"","md4":"","xl2":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","tile":""},on:{"click":function($event){(_vm.dialog = true), (_vm.dialogImg = _vm.$config.managerMedia + item.Image)}}},[_c('v-img',{attrs:{"src":_vm.$config.managerMedia + item.Image,"lazy-src":_vm.$config.managerMedia + item.Image,"aspect-ratio":"1"}},[_c('v-layout',{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-overlay',{attrs:{"absolute":"","color":"#000000","opacity":"0.1"}},[_c('v-icon',{staticStyle:{"opacity":"0.5"},attrs:{"large":""}},[_vm._v("mdi-magnify-plus-outline")])],1):_vm._e()],1),(item.Header && item.Header.length > 0)?_c('v-card-title',{staticStyle:{"word-break":"break-word"},attrs:{"primary-title":""}},[_c('h3',{staticClass:"text-subtitle-1 mb-0",domProps:{"innerHTML":_vm._s(item.Header)}})]):_vm._e(),_c('v-fade-transition',[(hover && _vm.$vuetify.breakpoint.smAndUp)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-magnify-plus-outline")])],1):_vm._e()],1)],1)]}}],null,true)})],1):_vm._e()}),1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{style:({
        backgroundImage: 'url(' + _vm.dialogImg + ')',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
      }),attrs:{"flat":"","tile":""}},[_c('v-card-actions',[_c('v-btn',{attrs:{"fab":"","small":"","fixed":"","top":"","right":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
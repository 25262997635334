<template>
  <div>
    <base-contentpage-banner :items="bannerList" />
    <base-contentpage-top-image :items="showInCarouselList" />
    <v-container fluid>
      <v-row wrap>
        <v-col class="pa-0" cols="12">
          <v-container class="px-0 ma-0" fluid>
            <v-row align="center" no-gutters class="child-flex">
              <template
                v-if="
                  ($store.state.session.authResult.checkin &&
                    $store.state.session.mode !== 'walkin' &&
                    $store.state.session.mode !== 'walkin-only') ||
                  $store.state.session.mode !== 'walkin-only'
                "
              >
                <v-col
                  v-if="
                    $store.state.session.authResult.checkin &&
                    $store.state.session.mode !== 'walkin' &&
                    $store.state.session.mode !== 'walkin-only'
                  "
                  cols="6"
                >
                  <signed-in-user-list-tile />
                </v-col>
                <v-col
                  v-else-if="$store.state.session.mode !== 'walkin-only'"
                  cols="6"
                >
                  <v-btn color="primary" @click.stop="logout" text block>
                    <v-icon left>mdi-account</v-icon>
                    {{ $t("login.signin") }}
                  </v-btn>
                </v-col>
              </template>
              <v-divider vertical />
              <v-col v-if="contactAvailable" class="text-center">
                <v-container>
                  <v-row>
                    <v-col
                      v-if="$store.state.session.settings.contact.telephone"
                    >
                      <v-btn
                        color="primary"
                        block
                        icon
                        :href="`tel:${$store.state.session.settings.contact.telephone}`"
                      >
                        <v-icon>mdi-phone</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="$store.state.session.settings.contact.whatsapp"
                    >
                      <v-btn
                        color="primary"
                        block
                        icon
                        :href="`https://api.whatsapp.com/send?phone=${$store.state.session.settings.contact.whatsapp}`"
                      >
                        <v-icon>mdi-whatsapp</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="$store.state.session.settings.contact.facebook"
                    >
                      <v-btn
                        color="primary"
                        block
                        icon
                        :href="`http://m.me/${$store.state.session.settings.contact.facebook}`"
                      >
                        <v-icon>mdi-facebook-messenger</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <template>
          <base-seperator />
          <v-col cols="12">
            <weather-widget />
          </v-col>
        </template>
        <template v-for="item in filteredList">
          <base-seperator :key="`divider-${item.Header}`" />
          <v-col :key="item.Header" cols="12">
            <base-contentpage-landingpage-card
              v-if="!item.sublist"
              :item="item"
              :line-clamp="false"
            />
            <div
              class="title pl-2 pb-2 primary--text"
              v-text="item.Header"
              v-else-if="!item.Image || (item.sublist && item.Header)"
            />
            <base-contentpage-landingpage-card-rounded-row
              v-if="item.RoundedSubItems == 1"
              :items="item.sublist"
            />
            <base-contentpage-landingpage-card-row
              v-else
              :items="item.sublist"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import WeatherWidget from "@/components/WeatherWidget";
import BaseContentpageLandingpageCard from "@/components/contentpage/base/BaseContentpageLandingpageCard";
import BaseContentpageLandingpageCardRow from "@/components/contentpage/base/BaseContentpageLandingpageCardRow";
import BaseContentpageLandingpageCardRoundedRow from "@/components/contentpage/base/BaseContentpageLandingpageCardRoundedRow";
import BaseContentpageTopImage from "@/components/contentpage/base/BaseContentpageTopImage";
import BaseContentpageBanner from "@/components/contentpage/base/BaseContentpageBanner";
import BaseSeperator from "@/components/contentpage/base/BaseSeperator";
import SignedInUserListTile from "@/components/SignedInUserListTile";

import LinkPrevue from "link-prevue";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { getRoute, playVideo, goTo } from "@/util/contentpage";

export default {
  components: {
    WeatherWidget,
    LinkPrevue,
    BaseContentpageLandingpageCard,
    BaseContentpageLandingpageCardRow,
    BaseContentpageLandingpageCardRoundedRow,
    BaseContentpageTopImage,
    BaseContentpageBanner,
    BaseSeperator,
    SignedInUserListTile,
  },
  props: {
    list: Array,
  },
  computed: {
    ...mapState({
      skinId: ({ session }) => session.skinId,
      authResult: ({ session }) => session.authResult,
      bannerList: ({ contentpage }) => contentpage.bannerList,
    }),
    ...mapGetters("contentpage", ["filteredList"]),
    showInCarouselList() {
      const showInCarouselList = [];
      this.list.forEach(function (listitem) {
        if (listitem.ShowInCarousel > 0) {
          showInCarouselList.push(listitem);
        }

        if (listitem.sublist) {
          listitem.sublist.forEach(function (subItem) {
            if (subItem.ShowInCarousel > 0) {
              showInCarouselList.push(subItem);
            }
          });
        }
      });

      return showInCarouselList;
    },
    contactAvailable() {
      const {
        telephone = false,
        whatsapp = false,
        facebook = false,
      } = this.$store.state.session.settings.contact;
      if (telephone || whatsapp || facebook) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.initBanner();
  },
  methods: {
    getRoute,
    playVideo,
    goTo,
    ...mapActions("contentpage", ["getBannerData"]),
    ...mapMutations("contentpage", {
      resetBannerList: "RESET_BANNER_DATA",
    }),
    logout() {
      this.$ga.event({
        eventCategory: "Logout",
        eventAction: "clicked",
        eventLabel: `clicked`,
        eventValue: 1,
      });
      this.$store.dispatch("language/deleteCookies");
      this.$store.dispatch("session/deleteLoginData");
      this.$store.commit("session/RESET");
      this.$router.push({ query: { autologin: undefined } });
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    initBanner() {
      this.resetBannerList();
      const basePath =
        this.$config.managerSkins +
        this.authResult.skin.project_id +
        "/" +
        this.skinId +
        "/oneapp/jscp/";

      this.list.forEach((listitem) => {
        if (listitem.ShowAsBanner > 0) {
          let route = this.getRoute(listitem);
          this.getBannerData({ id: route.params.cpId, basePath });
        }
      });
    },
  },
};
</script>

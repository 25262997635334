<template>
  <v-lazy
    :min-height="item.Image || item.Video ? $store.state.mediaHeight : ''"
  >
    <v-card outlined height="100%">
      <v-img
        ref="img"
        v-if="item.Image && item.Video"
        :src="
          $config.managerMedia +
          item.Image +
          `?resize&width=${$store.state.mediaWidth}`
        "
        :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
        :height="$store.state.mediaHeightText"
      >
        <v-overlay absolute color="grey darken-4">
          <v-btn @click="onClickVideo" icon x-large>
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-overlay>
      </v-img>
      <v-img
        v-else-if="item.Image"
        ref="img"
        :src="
          $config.managerMedia +
          item.Image +
          `?resize&width=${$store.state.mediaWidth}`
        "
        :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
        :height="$store.state.mediaHeightText"
      />
      <v-card-title
        v-if="item.Header || item.Description"
        class="title primary--text"
        style="word-break: break-word"
        primary-title
        v-html="item.Header"
      />
      <v-card-text
        v-if="item.Description"
        class="text-body-1"
        v-html="item.Description"
        style="white-space: pre-wrap"
      />
      <v-card-actions
        style="overflow-x: auto"
        v-if="
          item.Page ||
          item.OnClick ||
          item.Action1Title ||
          item.Action2Title ||
          item.Action3Title ||
          item.Action4Title
        "
      >
        <v-btn
          v-text="$t('app.info')"
          v-if="item.Page || item.OnClick"
          color="primary"
          text
          :to="to"
        />
        <v-btn v-if="item.Action1Title" color="primary" text :to="toAction1">
          <v-icon left v-if="item.Action1Icon">{{ item.Action1Icon }}</v-icon>
          {{ item.Action1Title }}
        </v-btn>
        <v-btn v-if="item.Action2Title" color="primary" text :to="toAction2">
          <v-icon left v-if="item.Action2Icon">{{ item.Action2Icon }}</v-icon>
          {{ item.Action2Title }}
        </v-btn>
        <v-btn v-if="item.Action3Title" color="primary" text :to="toAction3">
          <v-icon left v-if="item.Action3Icon">{{ item.Action3Icon }}</v-icon>
          {{ item.Action3Title }}
        </v-btn>
        <v-btn v-if="item.Action4Title" color="primary" text :to="toAction4">
          <v-icon left v-if="item.Action4Icon">{{ item.Action4Icon }}</v-icon>
          {{ item.Action4Title }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-lazy>
</template>

<script>
import { getRoute, playVideo } from "@/util/contentpage";

export default {
  props: {
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    to() {
      return getRoute({ onclick: this.item.OnClick, page: this.item.Page });
    },
    toAction1() {
      return getRoute({
        onclick: this.item.Action1OnClick,
        page: this.item.Action1Page,
      });
    },
    toAction2() {
      return getRoute({
        onclick: this.item.Action2OnClick,
        page: this.item.Action2Page,
      });
    },
    toAction3() {
      return getRoute({
        onclick: this.item.Action3OnClick,
        page: this.item.Action3Page,
      });
    },
    toAction4() {
      return getRoute({
        onclick: this.item.Action4OnClick,
        page: this.item.Action4Page,
      });
    },
  },
  methods: {
    onClickVideo() {
      playVideo(this.item.Video);
    },
  },
};
</script>

<template>
  <div>
    <v-banner
      v-for="item in items"
      :key="'base-contentpage-banner-' + item.Text"
    >
      <v-img
        slot="icon"
        v-if="item.Image"
        :src="$config.managerMedia + item.Image"
      />
      {{ item.Text }}
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-if="item.Video"
          @click="playVideo(item.Video)"
        >
          <v-icon class="pr-1">mdi-play</v-icon> {{ $t("app.play") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          v-if="item.Link1"
          @click="goTo(item.Link1)"
          v-text="item.Link1Text"
        />
        <v-btn
          text
          color="primary"
          v-if="item.Link2"
          @click="goTo(item.Link2)"
          v-text="item.Link2Text"
        />
        <v-btn
          text
          color="primary"
          v-if="item.Page"
          :to="getRoute(item)"
          v-text="$t('app.info')"
        />
        <v-btn
          text
          color="primary"
          v-else-if="item.Onclick"
          :to="getRoute(item)"
          v-text="$t('app.info')"
        />
      </template>
    </v-banner>
  </div>
</template>

<script>
import { getRoute, playVideo, goTo } from "@/util/contentpage";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getRoute,
    playVideo,
    goTo,
  },
};
</script>
